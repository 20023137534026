import dayjs from 'dayjs';
import FranjaHoraria from '../../../01.-Domain/Entities/FranjaHoraria';
import ToastService from '../../../02.-Application/Services/Base/ToastService';

const ValidacionFechas = (dateStart: Date, dateEnd: Date, franjas: FranjaHoraria[]): boolean => {
  if (!dateStart || !dateEnd) {
    ToastService.error('La franja tiene que tener hora de inicio y de final');
    return true;
  }

  const nuevoInicio = dayjs(dateStart);
  const nuevoFinal = dayjs(dateEnd);

  if (nuevoInicio.isSame(nuevoFinal)) {
    ToastService.error('La hora de inicio no puede ser la misma que la de fín');
    return true;
  }

  // Detectar si la nueva franja cruza la medianoche
  const cruzaMedianocheNuevo = nuevoInicio.isAfter(nuevoFinal);

  for (const element of franjas) {
    const inicioFranja = dayjs(element.inicio);
    const finFranja = dayjs(element.fin);
    const cruzaMedianocheExistente = inicioFranja.isAfter(finFranja);

    // Función auxiliar para verificar solapamientos
    const seSolapan = (
      inicioA: dayjs.Dayjs,
      finA: dayjs.Dayjs,
      inicioB: dayjs.Dayjs,
      finB: dayjs.Dayjs
    ) => {
      return (
        inicioA.isBetween(inicioB, finB, null, '[)') ||
        finA.isBetween(inicioB, finB, null, '[)') ||
        inicioB.isBetween(inicioA, finA, null, '[)') ||
        finB.isBetween(inicioA, finA, null, '[)')
      );
    };

    if (cruzaMedianocheNuevo || cruzaMedianocheExistente) {
      // Dividir franjas que cruzan la medianoche en dos partes y comprobar solapamientos
      const inicioParte1Nuevo = nuevoInicio;
      const finParte1Nuevo = dayjs(nuevoInicio).endOf('day'); // 23:59:59
      const inicioParte2Nuevo = dayjs(nuevoFinal).startOf('day'); // 00:00:00
      const finParte2Nuevo = nuevoFinal;

      const inicioParte1Existente = inicioFranja;
      const finParte1Existente = dayjs(inicioFranja).endOf('day'); // 23:59:59
      const inicioParte2Existente = dayjs(finFranja).startOf('day'); // 00:00:00
      const finParte2Existente = finFranja;

      if (
        seSolapan(inicioParte1Nuevo, finParte1Nuevo, inicioParte1Existente, finParte1Existente) ||
        seSolapan(inicioParte2Nuevo, finParte2Nuevo, inicioParte2Existente, finParte2Existente)
      ) {
        ToastService.error('La fecha de inicio se solapa con otra franja');
        return true;
      }
    } else {
      // Verificación normal si ninguna de las franjas cruza la medianoche
      if (seSolapan(nuevoInicio, nuevoFinal, inicioFranja, finFranja)) {
        ToastService.error('La fecha de inicio se solapa con otra franja');
        return true;
      }
    }
  }

  return false; // No hay solapes
};

export { ValidacionFechas };
